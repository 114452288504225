import React, { useState } from "react"
import { Box, Button, Card, Checkbox as ThemeUiCheckbox, Flex, Link, Text } from "theme-ui"
import { diffChars } from "diff"

import fileDownload from "js-file-download"
import { graphql } from "gatsby"

import { getLangLocalFileUrl, getStatusesCount, getEditedFileString, matchesSiteAndContent } from "../util"
import ExpandAllIcon from "../svg/expand-all.svg"
import { TRANSLATION_STATUS } from "../constants"
import DiffView from "../components/diff-view"
import Status from "../components/status"
import Icon from "../components/icon"

import FileLayout from "../layouts/file-layout"
import Editable from "../components/editable"
import { DownloadButton, ExternalLink } from "../components/download-buttons"


const TABLE_COLUMNS = ['260px', '136px', 'auto', '20px']
const STATUS_PADDING = '10px'

const Diff = ({a, b}) => {
  const diff = diffChars(a, b)
  const text = diff.map(function(part, index){
    // green for additions, red for deletions
    // grey for common parts
    const color = part.added ? 'primary' : 'text';

    return <Text key={index} sx={{color: color, display: 'inline', ...{
        textDecoration: part.removed ? "line-through" : "none",
        opacity: part.removed ? '0.25' : '1.0'}
    }}>{part.value}</Text>
  });

  return <span>{text}</span>
}

const StatusInfoFilter = ({statusInfo, statusList}) => {
  const [filters, setFilters] = useState({
    [TRANSLATION_STATUS.MISSING]: true,
    [TRANSLATION_STATUS.OUTDATED]: true,
    [TRANSLATION_STATUS.COPIED]: true
  })

  // Figure out which rows should be highlighted
  // to achieve the alternating rows effect
  // (not possible to do through css alone because
  // some rows have display:none)
  const shownRows = statusList
    .map((status, index) => ({status, index}))
    .filter(({status}) => filters[status])
    .map(row => row.index)
  const highlightedRows = shownRows.filter((rowIndex, index) => index % 2 === 0)

  return <div>
    <span>Filter: </span>
    {statusInfo.map(({ status, count }) => <span key={status}>
      <input
        type="checkbox"
        checked={!!filters[status]}
        onChange={e => {
          setFilters({
            ...filters,
            [status]: e.target.checked,
          })
        }

        }
      />
      <Status status={status}/><Text variant="caps" sx={{ display: "inline", fontSize: 1 }}>: {count}{" "}</Text>
    </span>)}
    {/*
      This way of filtering shows a significant performance improvement
      over filtering rows at the component level.
     */}
    <style dangerouslySetInnerHTML={{__html:
        `.stats-row {display: none;} \n` +
        Object.entries(filters)
          .map(([status, enabled]) => `.row-status_${status} { display: ${enabled ? "table-row-group" : "none"}; }`)
          .join('\n') + '\n' +
        highlightedRows
          .map(rowIndex => `.row-${rowIndex}`)
          .join(',') + ' { background-color: rgba(200,200,200,0.2); }'
    }} />
  </div>
}


const HiddenRow = ({desc, content, show = false, sx, ...props}) => <Box
  as="tr"
  sx={{display: show ? 'table-row' : 'none', py: 2, ...sx}}
  {...props}
>
  <td/>
  <td>
    <Text sx={{pl: STATUS_PADDING}}>{desc}</Text>
  </td>
  <td colSpan={2}>
    <Text>{content}</Text>
  </td>
</Box>

const Checkbox = ({checked, sx, ...props}) => <Box sx={{cursor: 'pointer'}} {...props}>
  <ThemeUiCheckbox sx={{mr: 0}} checked={checked} />
</Box>

const StatsRow = ({msgKey, msgMeta, site, englishMessages, editedValue, onEdit, className, forceExpand}) => {
  const [expanded, setExpanded] = useState(false)


  return <Box as="tbody"
              sx={{'td': {py: '10px', mr: '16px'}}}
              className={`row-status_${msgMeta.status} stats-row ${className}`}
  >
    <Box as="tr"
         onClick={() => !forceExpand && setExpanded(!expanded)}
         sx={forceExpand ? {} : {
           cursor: 'pointer',
           transition: 'box-shadow 0.12s',
           boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.25)',
           '.carat': { opacity: 0.7 },
           ':hover': {
             boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)',
             '.carat': { opacity: '1.0'}
           }}}
    >
      <Box as="td" sx={{
        width: TABLE_COLUMNS[0],
        maxWidth: TABLE_COLUMNS[0],
        overflow: 'hidden',
        position: 'relative',
        pl: 2,
        '&:hover': {overflow: 'visible', '& > div': {backgroundColor: 'rgba(255,255,255,0.9)'}}
      }}>
        <Text sx={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', pr: '10px'}}>{msgKey}</Text>
      </Box>
      <Box as="td" sx={{width: TABLE_COLUMNS[1], pl: STATUS_PADDING}}>
        <Status status={msgMeta.status}/>
      </Box>
      <Box as="td" sx={{width: TABLE_COLUMNS[2]}}>
        <Text>{englishMessages[msgKey]}</Text>
      </Box>
      <Box as="td" sx={{width: TABLE_COLUMNS[3], pr: 1}}>
        {!forceExpand && <Icon name="chevron_down" className="carat" size="18px" sx={{
          transform: `rotate(${expanded ? 180 : 0}deg)`,
          transition: 'transform 0.2s ease, opacity 0.12s'}}
        />}
      </Box>
    </Box>
    <HiddenRow
      desc={editedValue ? 'translation edit' : `translated as${msgMeta.status === TRANSLATION_STATUS.OUTDATED ? '*' : ''}`}
      sx={{ 'td': {borderTop: "1px dotted rgba(0,0,0,0.1)"}}}
      content={
        <Editable
          value={msgMeta.status === TRANSLATION_STATUS.MISSING ? <i>(none)</i>:msgMeta.value}
          editedValue={editedValue}
          onEdit={onEdit}
        />
      }
      show={expanded || forceExpand}
    />
    {msgMeta.status === TRANSLATION_STATUS.OUTDATED && msgMeta.translationFor &&
    [
      <HiddenRow
        key="English translation source"
        desc="*English text at time of translation"
        content={msgMeta.translationFor}
        show={expanded || forceExpand}
        title="This is the English text that was present when the translation was created."
      />
      ,
      <HiddenRow
        key="diff"
        desc="difference with current"
        content={<Diff a={msgMeta.translationFor} b={englishMessages[msgKey]} />}
        show={expanded || forceExpand}
      />
    ]
    }

    {msgMeta.commitSha &&
    <HiddenRow
      content={<a href={`https://github.com/${site.repo}/commit/${msgMeta.commitSha}`} target="_blank"
                  rel="nofollow noopener noreferrer">View commit</a>}
      show={expanded || forceExpand}
    />
    }
  </Box>
}

function getMessagesFromMeta(translationMeta) {
  return Object.entries(translationMeta).reduce((acc, [key, msgMeta]) => {
    acc[key] = msgMeta.value
    return acc
  }, {})
}

const TranslationStatsJson = ({pageContext: {site, lang, filePathInRepo, contentName, buildDate }, data}) => {
  const [edited, setEdited] = useState({})
  const [diffView, setDiffView] = useState()
  const [expandAll, setExpandAll] = useState(false)

  const sitesData = JSON.parse(data.sitesData.content)
  const { messages } = sitesData
    .find(data => matchesSiteAndContent(data, { site, content: { name: contentName }}))
  const translationMeta = messages.translated.find(mges => mges.lang === lang).translationMeta
  const englishMessages = messages.english
  const originalFile = data.translationFile && data.translationFile.content
  const statusInfo = getStatusesCount(translationMeta).filter(info => info.count)
  const editedCount = Object.values(edited).filter(msg => msg).length

  function downloadEdited() {
    const fileName = filePathInRepo.split('/').slice(-1)[0]
    fileDownload(getEditedFileString(getMessagesFromMeta(translationMeta), edited), fileName)
  }

  return <FileLayout {...{site, lang, contentName, buildDate}}>
    <Flex sx={{justifyContent: 'flex-end'}}>
      <Flex sx={{
        justifyContent: 'space-around',
        p: 3,
        pt: 2
      }}>

        {originalFile ?
          <Box sx={{ mr: 5 }}>
            <a href={getLangLocalFileUrl(site, lang, filePathInRepo)} download>
              <DownloadButton variant="outline">
                Download original
              </DownloadButton>
            </a>
            <br/>
            <ExternalLink href={`https://github.com/${site.repo}/blob/${site.branch}/${filePathInRepo}`}>
              View on Github
            </ExternalLink>
          </Box> :
          <Box sx={{ mr: 5, maxWidth: '188px' }}>
            <DownloadButton variant="outline" disabled>
              Download original
            </DownloadButton>
            <br/>
            <Text sx={{fontSize: "14px"}}>No file found at {filePathInRepo}</Text>
          </Box>
        }
        <Box sx={{mr: 4}}>
          <DownloadButton onClick={() => downloadEdited()} disabled={editedCount === 0}>
            Download {originalFile ? 'edited' : 'new file'}
          </DownloadButton>
          <br />
          {originalFile ?
            <Link sx={{
              cursor: editedCount > 0 ? 'pointer' : 'default',
              opacity: editedCount > 0 ? '1.0' : '0.2'
            }} onClick={() => {
              if (editedCount === 0) {
                return;
              }
              setDiffView(<DiffView
                originalFile={originalFile}
                editedFileString={getEditedFileString(getMessagesFromMeta(translationMeta), edited)}
              />)
            }}>
              View diff ({editedCount})
            </Link> : null
          }
        </Box>
      </Flex>
    </Flex>
    {diffView && <Box sx={{position: 'absolute', top: '80px', left: '40px', right: '40px', zIndex: '10'}}>
      <Card sx={{ mb: '40px'}}>
        <Flex sx={{justifyContent: 'flex-end'}}>
          <Button sx={{py: '10px'}} onClick={() => setDiffView(null)}>Close</Button>
        </Flex>
        <Flex sx={{justifyContent: 'space-between', fontSize: 5}}>
          <Text>Current {lang} file</Text>
          <Text>Edited {lang} file</Text>
        </Flex>
        {diffView}
      </Card>
    </Box>}
    <Flex sx={{  justifyContent: "center" }}>
      {
        statusInfo &&
        <Box sx={{
          p: 1,
          px: 3,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          backgroundColor: '#f5f8fd'
        }}>
          <StatusInfoFilter
            statusInfo={statusInfo}
            statusList={Object.values(translationMeta).map(m => m.status)}
          />
        </Box>
      }
    </Flex>
    <Box as="table"
         sx={{
           width: '100%',
           borderBottom: "1px solid",
           borderTop: "1px solid",
           borderColor: "muted",
           borderSpacing: "0",
           px: 2,
           py: 1,
         }}
    >
      <Box as="thead" sx={{fontWeight: 'bold'}}>
        <tr>
          <Text as="td" sx={{width: TABLE_COLUMNS[0]}}>id</Text>
          <Text as="td" sx={{width: TABLE_COLUMNS[1]}}>status</Text>
          <Text as="td" sx={{width: TABLE_COLUMNS[2]}}>english text</Text>
          <Text as="td"
                sx={{width: TABLE_COLUMNS[3], cursor: 'pointer'}}
                onClick={() => setExpandAll(state => !state)}
          >
            <ExpandAllIcon style={expandAll ? { stroke: 'none', fill: '#000'} : { stroke: '#000', fill: 'none'}} />
          </Text>
        </tr>
      </Box>
    </Box>
    <Box
      sx={{
        maxHeight: '70vh',
        overflow: "auto",
        borderBottom: "1px solid",
        borderColor: "muted",
        "&::-webkit-scrollbar": {
          width: "5px",
          minWidth: "5px"
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "foreground",
          borderRadius: "small"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "muted",
          borderRadius: "small"
        }
      }}
    >
      <Box as="table" sx={{
        borderSpacing: "0",
        width: "100%"
      }}>
        {Object.entries(translationMeta).map(([key, msgMeta], index) =>
          <StatsRow
            key={key}
            site={site}
            msgKey={key}
            msgMeta={msgMeta}
            englishMessages={englishMessages}
            editedValue={edited[key]}
            onEdit={(value) => setEdited({...edited, [key]:value})}
            className={`row-${index}`}
            forceExpand={expandAll}
          />
        )}
      </Box>
    </Box>
  </FileLayout>
}

export default TranslationStatsJson

export const query = graphql`
  query LanguageStatsQuery($fileLocalPath: String) {
    translationFile: rawFile(relativePath: {eq: $fileLocalPath}) {
      content
    }
    sitesData: rawFile(name: {eq: "sites-data"}) {
      content
    }
  }  
`
